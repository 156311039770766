import React, { Component } from 'react';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import { PrimaryButton } from 'components/Shared/Button/Button';
import FormField from 'components/Shared/Form/FormField/FormField';
import { sendEmailUsRequest } from 'components/Shared/FormHandlers';
import Validators, { composeValidators } from 'components/Shared/Form/validators';
import { FIELD_TYPES } from '../Shared/Form/FormField/FormField';
import IconExclamation from 'static/images/icons/exclamation-circle.inline.svg';
import IconThumbsUp from 'static/images/icons/icon-thumbs-up.inline.svg';
import './EmailUsForm.scss';


class EmailUsForm extends Component {
  constructor(props) {
    super(props);
    this.decorators = [];
    this.validators = {
      name: composeValidators(
        Validators.required(),
        Validators.text()
      ),
      email: composeValidators(
        Validators.required(),
        Validators.email()
      ),
      topic: composeValidators(
        Validators.required()
      ),
      message: composeValidators(
        Validators.required()
      )
    };
    this.state = {
      submittedName: null
    }
    this.form = null;
  }

  reset = () => {
    this.form.form.reset();
    this.form.form.resetFieldState('name');
    this.form.form.resetFieldState('email');
    this.form.form.resetFieldState('topic');
    this.form.form.resetFieldState('message');
  }

  submit = async values => {
    try {
      await sendEmailUsRequest(values);
      this.setState({submittedName: values.name});
      return {};
    } catch (e) {
      window.Sentry.captureException(e);
      return { [FORM_ERROR]: this.props.data.submissionError || 'Form submitting failed' };
    }
  };

  render() {
    const { data } = this.props;
    const validators = this.validators;
    return (
      <section className={`email-us-form ${this.props.className || ''}`}>
        <h2 className='email-us-form__title'>{data.title}</h2>
        <Form
          decorators={this.decorators}
          onSubmit={this.submit}
          ref={(form) => { this.form = form; }}
          render={({ handleSubmit, pristine, submitError, submitting, hasValidationErrors }) => (
            <form 
              className="email-us-form__container"
              onSubmit={event => {
                handleSubmit(event).then( (res) => { 
                  if(!res[FORM_ERROR]) {
                    this.reset();
                  }
                });
              }}
            >
              <div className="row">
                <div className="col-12 col-sm-6">
                  <FormField 
                    data={{
                      label: 'Name',
                      name: 'name',
                      required: true,
                      type: FIELD_TYPES.text
                    }}
                    validate={validators.name}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <FormField 
                    data={{
                      label: 'Email',
                      name: 'email',
                      required: true,
                      type: FIELD_TYPES.email
                    }}
                    validate={validators.email}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <FormField 
                    data={{
                      label: 'Topic',
                      name: 'topic',
                      options: [
                        {label: 'Current member needing tech support', value: 'support'},
                        {label: 'Interested in learning more about AAA Smart Home', value: 'learning'},
                      ],
                      required: true,
                      type: FIELD_TYPES.radio
                    }}
                    validate={validators.topic}
                  />
                </div>
              </div>
              <div className="row email-us-form__textarea-box">
                <div className="col-12 d-flex flex-column align-self-stretch">
                  <FormField
                    data={{
                      label: 'Message',
                      name: 'message',
                      required: true,
                      type: FIELD_TYPES.textarea,
                    }}
                    validate={validators.message}
                  />
                </div>
              </div>
              <div className="email-us-form__submit-box">
                <PrimaryButton
                  className="email-us-form__submit-button"
                  disabled={hasValidationErrors || pristine || submitting}
                  type="submit"
                >
                    {data.submitButton}
                </PrimaryButton>
                { submitError && (
                  <div className="email-us-form__submit-error">
                    <IconExclamation className="icon"/>
                    <div dangerouslySetInnerHTML={{ __html: submitError }} />
                  </div>
                )}
              </div>
            </form>
          )}
        />
        <div 
          className='email-us-form__disclaimer'
          dangerouslySetInnerHTML={{ __html: data.disclaimer }}
        />
        { 
          this.state.submittedName && 
          <div className="email-us-success">
            <h2 className="email-us-success__title">
              {`${data.success.title} ${this.state.submittedName}!`}
              <IconThumbsUp className="email-us-success__thumbs-up" />
            </h2>
            <div 
              className="email-us-success__description"
              dangerouslySetInnerHTML={{ __html: data.success.description }}
            />
            <div className="email-us-success__logo">
              <img src={data.success.logo.publicURL} />
            </div>
          </div>
        }
      </section>
    );
  }
}

export default EmailUsForm;
