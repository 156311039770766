import React from 'react';
import Link from 'components/Shared/Link/Link';
import IconContacts from 'static/images/icons/icn-directory.inline.svg';
import IconGuides from 'static/images/icons/icn-product-documents.inline.svg';
import IconBranches from 'static/images/icons/icn-find-a-branch.inline.svg';
import IconProducts from 'static/images/icons/icn-surveilance.inline.svg';
import IconSolutions from 'static/images/icons/icn-solutions.inline.svg';
import './HelpfulLinks.scss';

const iconsMap = {
  contacts: IconContacts,
  guides: IconGuides,
  branches: IconBranches,
  products: IconProducts,
  solutions: IconSolutions,
};

const HelpfulLinks = ({ data }) => (
  <section className="aaa-helpful-links">
    <h2 className="aaa-helpful-links__title">{data.title}</h2>
    <div className="row">
      {data.items.map(item => {
        const Icon = iconsMap[item.icon];
        return (
          <div key={item.title} className="col-12 col-sm-4">
            <Link className="aaa-helpful-link" to={item.link} target={item.newTab && "_blank"}>
              <Icon className="aaa-helpful-link__icon" />
              <span className="aaa-helpful-link__title">{item.title}</span>
            </Link>
          </div>
        );
      })}
    </div>
  </section>
);

export default HelpfulLinks;
