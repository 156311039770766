import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import CallUsBlock from './CallUsBlock';
import EmailUsForm from './EmailUsForm';
import HelpfulLinks from './HelpfulLinks';
import './HelpContainer.scss';


const HelpContainer = ({ data }) => (
  <article className="aaa-help-container">
    <ContentBox>
      <div className="row">
        <div className="col-12 col-lg-4">
          <CallUsBlock data={data.callUs} />
        </div>
        <div className="col-12 col-lg-8">
          <EmailUsForm data={data.emailUs} />
        </div>
      </div>
      <HelpfulLinks data={data.helpfulLinks} />
    </ContentBox>
  </article>
);

export default HelpContainer;
