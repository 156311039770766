import React from 'react';
import './CallUsBlock.scss';


const CallUsBlock = ({ data }) => (
  <section className="aaa-call-us">
    <h2 className="aaa-call-us__title">
      { data.title }
    </h2>
    <div className="row">
      { data.items.map( (item, i) => (
        <div className="col-12 col-md-6 col-lg-12" key={`callus_${i}`}>
          <div className="aaa-call-us__item-title">
            { item.title }
          </div>
          <div 
            className="aaa-call-us__item-description"
            dangerouslySetInnerHTML={{
              __html: item.description
            }}
          />
        </div>
    ))}
    </div>
  </section>
);

export default CallUsBlock;
